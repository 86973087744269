import { alias, primitive, serializable } from "serializr";

export class Payment {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('customer_id', primitive()))
    customerId?: string;

    @serializable(alias('customer_name', primitive()))
    customerName?: string;

    @serializable(alias('date', primitive()))
    date?: string;

    @serializable(alias('amount', primitive()))
    amount?: number;

    @serializable(alias('payment_type', primitive()))
    paymentType?: string;

    @serializable(alias("is_cancellable", primitive()))
    isCancellable?: boolean;
}