import { useState } from "react";
import { Payment } from "../../models/Payments/payment.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { PaginationWrapper } from "../../models/PaginationWrapper/paginationWrapper.model";
import { generatePath } from "react-router-dom";

const PaymentService = () => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<Payment[]>();
  const [pagination, setPagination] = useState<PaginationWrapper>();

  const getPaymentDetails = async (params?: {
    page?: number;
    search?: string;
    sort_dir?: string;
    sort_by?: string;
  }) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.PAYMENT, { params });
      const data = deserialize(
        Payment,
        response?.data["payments"]["data"]
      ) as Payment[];
      const pagination = deserialize(
        PaginationWrapper,
        response?.data["payments"]
      );
      setPayments(data);
      setPagination(pagination);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const cancelPayment = async (id: number) => {
    const path = generatePath(ApiRoutes.CANCEL_PAYMENTS, {
      id: id,
    });

    try {
      setLoading(true);
      const response = await axiosInstance.patch(path);
      return response;
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return {
    getPaymentDetails,
    loading,
    pagination,
    payments,
    cancelPayment
  };
};

export default PaymentService;
